import React from 'react';

import css from './SectionHero.module.css';
import { FormattedMessage } from 'react-intl';
import { NamedLink } from '../../../components';

const SectionHero = props => {

  return (
    <div className={css.root}>

      <div className={css.container}>
        <p className={css.title}>
          <FormattedMessage id="LandingPage.SectionHero.title" />
        </p>

        <NamedLink name="SearchPage" className={css.button}>
          <FormattedMessage id="LandingPage.SectionHero.viewUpcomingEvents" />
        </NamedLink>

        <p className={css.info}>
          <FormattedMessage id="LandingPage.SectionHero.info" />
        </p>
      </div >
    </div >
  );
};


export default SectionHero;
