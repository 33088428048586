import React from 'react';
import { object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { useConfiguration } from '../../context/configurationContext';
import { injectIntl, intlShape } from '../../util/reactIntl';

import { Page, LayoutSingleColumn } from '../../components';

import TopbarContainer from '../../containers/TopbarContainer/TopbarContainer';
import FooterContainer from '../../containers/FooterContainer/FooterContainer';


import css from './LandingPage.module.css';
import SectionHero from './SectionHero/SectionHero';
import { queryRecentListings } from './LandingPage.duck';
import { isScrollingDisabled } from '../../ducks/ui.duck';
import SectionRecentListings from './SectionRecentListings/SectionRecentListings';
import SectionHowItWorks from './SectionHowItWorks/SectionHowItWorks';


export const LandingPageComponent = props => {
  const config = useConfiguration();
  const {
    scrollingDisabled,
    intl,
    queryListingsError,
    queryListingsInProgress,
    listings,
    onQueryRecentListings
  } = props;

  const title = intl.formatMessage({ id: 'LandingPage.title' });

  return (
    <Page className={css.root} title={title} scrollingDisabled={scrollingDisabled}>
      <LayoutSingleColumn
        topbar={
          <>
            <TopbarContainer />
          </>
        }
        footer={<FooterContainer />}
      >
        <div className={css.content}>

          <SectionHero />
          <SectionHowItWorks />

          <SectionRecentListings
            queryListingsError={queryListingsError}
            queryListingsInProgress={queryListingsInProgress}
            listings={listings}
            onQueryRecentListings={onQueryRecentListings}
            config={config} />

        </div>
      </LayoutSingleColumn>
    </Page>
  );
};

LandingPageComponent.defaultProps = {
  config: null,
};

LandingPageComponent.propTypes = {
  config: object,
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    queryListingsError,
    queryListingsInProgress,
    listings,
  } = state.LandingPage;


  return {
    queryListingsError,
    queryListingsInProgress,
    listings,
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const mapDispatchToProps = dispatch => ({
  onQueryRecentListings: (config) => dispatch(queryRecentListings(config)),
});

const LandingPage = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(LandingPageComponent);

export default LandingPage;
