import React from 'react';

import css from './SectionHowItWorks.module.css';
import { FormattedMessage } from 'react-intl';
import { NamedLink } from '../../../components';
import PlaceIcon from '@mui/icons-material/Place';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import SportsVolleyballIcon from '@mui/icons-material/SportsVolleyball';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';

const SectionHowItWorks = props => {

  return (
    <div className={css.root}>

      <h2 className='titleMedium'>
        <FormattedMessage id="LandingPage.SectionHowItWorks.title" />
      </h2>


      <div className={css.cardsContainer}>
        <p className={css.title}>
          <FormattedMessage id="LandingPage.SectionHowItWorks.playing" />
        </p>

        <div className='rowResponsive gap10'>
          <div className={css.card1}>
            <p className={css.text}>
              <FormattedMessage id="LandingPage.SectionHowItWorks.playing1" />
            </p>
            <PlaceIcon className={css.icon} />
          </div>
          <div className={css.card2}>
            <p className={css.text}>
              <FormattedMessage id="LandingPage.SectionHowItWorks.playing2" />
            </p>
            <PersonAddAlt1Icon className={css.icon} />
          </div>
          <div className={css.card3}>
            <p className={css.text}>
              <FormattedMessage id="LandingPage.SectionHowItWorks.playing3" />
            </p>
            <SportsVolleyballIcon className={css.icon} />
          </div>
        </div>
      </div>

      <div className={css.cardsContainer}>
        <p className={css.title}>
          <FormattedMessage id="LandingPage.SectionHowItWorks.hosting" />
        </p>

        <div className='rowResponsive gap10'>
          <div className={css.card1}>
            <p className={css.text}>
              <FormattedMessage id="LandingPage.SectionHowItWorks.hosting1" />
            </p>
            <PlaceIcon className={css.icon} />
          </div>
          <div className={css.card2}>
            <p className={css.text}>
              <FormattedMessage id="LandingPage.SectionHowItWorks.hosting2" />
            </p>
            <EventAvailableIcon className={css.icon} />
          </div>
          <div className={css.card3}>
            <p className={css.text}>
              <FormattedMessage id="LandingPage.SectionHowItWorks.hosting3" />
            </p>
            <DirectionsRunIcon className={css.icon} />
          </div>
        </div>
      </div>

    </div >
  );
};


export default SectionHowItWorks;
