import React, { useEffect } from 'react';

import css from './SectionRecentListings.module.css';
import { FormattedMessage } from 'react-intl';
import { ListingCard } from '../../../components';

const SectionRecentListings = props => {
  const { queryListingsError,
    queryListingsInProgress,
    onQueryRecentListings,
    listings,
    config } = props;


  useEffect(() => {
    if (listings === null) {
      onQueryRecentListings(config);
    }
  }, [])

  const cardRenderSizes =
    [
      '(max-width: 549px) 100vw',
      '(max-width: 767px) 50vw',
      `(max-width: 1439px) 26vw`,
      `(max-width: 1920px) 18vw`,
      `14vw`,
    ].join(', ');

  return (
    <div className={css.root}>


      <h2 className='titleMedium'>
        <FormattedMessage id="LandingPage.SectionRecentListings.title" />
      </h2>

      <div className={css.listingsContainer}>
        {listings?.map(l => (
          <ListingCard
            className={css.listingCard}
            key={l.id.uuid}
            listing={l}
            renderSizes={cardRenderSizes}
            setActiveListing={() => { }}
          />
        ))}
      </div>

      {/* <div>
          <h4 className='titleBlueSmall'>
            <FormattedMessage id="LandingPage.SectionRecentListings" />
          </h4>

         
        </div>

        <p className='infoSmall'>
          <FormattedMessage id="LandingPage.SectionRecentListings.info" />
        </p>

        <div className='darkGreenButton maxWidth100'>
          <FormattedMessage id="LandingPage.SectionRecentListings.learnMore" />
        </div> */}
    </div >
  );
};


export default SectionRecentListings;
